// src/services/bettingService.js
const API_BASE_URL = 'https://api.dale365.com/api/v1/betting';
// const API_BASE_URL = 'http://localhost:8000/api/v1/betting';

async function fetchFromApi(endpoint, options = {}) {
  const url = `${API_BASE_URL}${endpoint}`;
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${localStorage.getItem('userToken')}`
  };

  const config = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers
    }
  };

  try {
    const response = await fetch(url, config);
    if (response.status === 401) {
      const data = await response.json();
      if (data.detail === 'Invalid token.') {
        // Deslogar usuário e redirecionar para a página de login
        localStorage.removeItem('userToken');
        localStorage.setItem('userLoggedIn', 'false');
        window.location.href = '/login';
      }
    }
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }
    // If the response status is 204 (No Content), return null
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Erro ao fazer requisição para a API:', error);
    throw error;
  }
}

export function createBettingAccount(data) {
  return fetchFromApi('/accounts/', {
    method: 'POST',
    body: JSON.stringify(data)
  });
}

export function updateBettingAccount(accountId, data) {
  return fetchFromApi(`/accounts/${accountId}/`, {
    method: 'PUT',
    body: JSON.stringify(data)
  });
}

export function deleteBettingAccount(accountId) {
  return fetchFromApi(`/accounts/${accountId}/`, {
    method: 'DELETE'
  });
}

export function createAccountBalance(data) {
  console.log('Sending data to createAccountBalance:', data); // Log para verificar os dados enviados
  return fetchFromApi('/balance/', {
    method: 'POST',
    body: JSON.stringify(data)
  });
}

export function deleteAccountBalance(balanceId) {
  return fetchFromApi(`/balance/${balanceId}/`, {
    method: 'DELETE'
  });
}

export function getDailySummary(userId, month, year) {
  return fetchFromApi(`/accounts/daily-summary/${userId}/?month=${month}&year=${year}`);
}

export function getMonthlyWithdrawals(userId, year) {
  return fetchFromApi(`/accounts/monthly-withdrawals/${userId}/?year=${year}`);
}

export function getAnnualDeposits(userId, year) {
  return fetchFromApi(`/accounts/annual-deposits-total/${userId}/?year=${year}`);
}

export function getUserBalanceRanking() {
  return fetchFromApi(`/accounts/user-balance-ranking/`);
}

export function getAccountBalances(accountId) {
  return fetchFromApi(`/accounts/${accountId}/`);
}

export function createWithdraw(accountId, data) {
  return fetchFromApi(`/withdraw/${accountId}/`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
}

export function deleteWithdraw(withdrawId) {
  return fetchFromApi(`/withdraw/${withdrawId}/`, {
    method: 'DELETE'
  });
}

export function getAccountWithdraws(accountId) {
  return fetchFromApi(`/accounts/${accountId}/`);
}

export function getActiveAccountsBalance() {
  return fetchFromApi('/active-accounts-balance/');
}

export function getBettingAccounts() {
  return fetchFromApi('/accounts/');
}