<template>
  <DaleHeader />
  <div class="live-page-container">
    <div class="matches-column">
      <LiveMatchCard />
      <UpcomingMatchesCard />
    </div>
    <div class="top-players-column">
      <TopPlayersCard title="Top 5 melhores jogadores do dia" rankingType="top_5_best" />
      <TopPlayersCard title="Top 5 piores jogadores do dia" rankingType="top_5_worst" />
    </div>
    <div class="additional-stats-column">
      <BarChartCard v-for="bot in botPermissions" :key="bot.id" :title="`Últimos 15 dias - ${bot.name}`" :botId="bot.id" />
    </div>
  </div>
</template>

<script>
import DaleHeader from '../components/Header.vue';
import LiveMatchCard from '../components/Live/LiveMatchCard.vue';
import UpcomingMatchesCard from '../components/Live/UpcomingMatchesCard.vue';
import TopPlayersCard from '../components/Live/TopPlayersCard.vue';
import BarChartCard from '../components/Live/BarChartCard.vue';

export default {
  name: 'AoVivo',
  components: {
    DaleHeader,
    LiveMatchCard,
    UpcomingMatchesCard,
    TopPlayersCard,
    BarChartCard,
  },
  data() {
    return {
      botPermissions: []
    };
  },
  mounted() {
    const storedBots = JSON.parse(localStorage.getItem('botPermissions') || '[]');
    const customOrder = [1, 4, 5, 7, 2, 3];
    
    this.botPermissions = storedBots.sort((a, b) => {
      const indexA = customOrder.indexOf(a.id);
      const indexB = customOrder.indexOf(b.id);
      
      // If both IDs are in the custom order list
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      
      // If only one ID is in the custom order list, prioritize it
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;
      
      // For IDs not in the custom order list, sort by ID
      return a.id - b.id;
    });
  }
};
</script>

<style scoped>
.live-page-container {
  display: grid;
  grid-template-columns: 1fr 1fr auto; /* Define três colunas */
  gap: 20px; /* Aumenta o espaçamento entre as colunas */
  padding: 20px; /* Aumenta o padding para um visual mais espaçado */
  background-color: #2c2c2c; /* Fundo mais escuro para contraste */
  border-radius: 12px; /* Bordas arredondadas para um visual moderno */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para dar profundidade */
}

.matches-column,
.top-players-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: none; /* Não permite que essas colunas cresçam */
}

.matches-column > *,
.top-players-column > * {
  flex: none;
  background-color: #3a3a3a; /* Fundo dos cards */
  border-radius: 8px; /* Bordas arredondadas dos cards */
  padding: 15px; /* Padding interno dos cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve nos cards */
}

.additional-stats-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .live-page-container {
    grid-template-columns: 1fr; /* Altera para uma coluna em telas menores */
  }

  .matches-column,
  .top-players-column,
  .additional-stats-column {
    min-width: 100%;
    margin: 0;
  }

  .matches-column > *,
  .top-players-column > *,
  .additional-stats-column > * {
    margin-bottom: 20px;
  }
}
</style>
