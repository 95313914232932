<template>
  <div class="live-matches-container">
    <h2 class="live-matches-title">Partidas Ao Vivo</h2>
    <div class="match-cards-container">
      <VueSpinner v-if="isLoading" class="spinner-container"/>
      <template v-if="matches.length">
        <div
          class="match-card"
          v-for="match in matches"
          :key="match.id"
        >
          <div class="match-teams">
            <span>{{ match.player_home }} x {{ match.player_away }}</span>
          </div>
          <div class="match-details">
            <div class="match-date">{{ match.time }}</div>
            <div class="match-score">{{ match.resultado }}</div>
            <div class="match-bots" v-if="match.bots.length">
              <template v-for="(bot, index) in match.bots" :key="index">
                <BotBadge :botName="bot" />
              </template>
            </div>
            <div class="match-icon-placeholder" v-else></div>
          </div>
        </div>
      </template>
      <div v-if="!matches.length && !isLoading" class="no-results">
        Não há partidas ao vivo no momento.
      </div>
    </div>
  </div>
</template>

<script>
import { VueSpinner } from 'vue3-spinners';
import { fetchLiveMatches } from '@/services/matchesService';
import BotBadge from '@/components/BotBadge.vue';

export default {
  name: 'LiveMatchCard',
  components: {
    VueSpinner,
    BotBadge
  },
  data() {
    return {
      matches: [],
      isLoading: false,
    };
  },
  methods: {
    async fetchMatches() {
      try {
        this.isLoading = true; // Activate the loading indicator immediately
        const data = await fetchLiveMatches();

        if (Array.isArray(data.results)) {
          this.matches = data.results.map(match => {
            // Extrair a data e hora do match.data no formato "13/07 04:46"
            const matchData = match.data;
            const [datePart, timePart] = matchData.split(' ');
            const [day, month] = datePart.split('/');
            const [hours, minutes] = timePart.split(':');
            const now = new Date();
            const matchTime = new Date(now.getFullYear(), month - 1, day, hours, minutes);
            const formattedTime = matchTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

            // Extrair o último resultado após o último traço
            let ultimoResultado = 'Aguardando resultado';
            if (match.time_gols) {
              const golsArray = match.time_gols.split('] [');
              if (golsArray.length > 0) {
                // Pegamos o último elemento e removemos os colchetes restantes
                const lastElement = golsArray[golsArray.length - 1];
                // Remove qualquer colchete de abertura no início e colchete de fechamento no final
                const cleanedResult = lastElement.replace(/^\[|\]$/g, '');
                // Pegamos o valor após o último " - "
                const parts = cleanedResult.split(' - ');
                if (parts.length > 1) {
                  ultimoResultado = parts[parts.length - 1];
                }
              }
            }

            return {
              id: match.id,
              player_home: match.player_home,
              player_away: match.player_away,
              time: formattedTime,
              resultado: ultimoResultado.replace('-', 'x'),
              bots: match.bots
            };
          });
        } else {
          console.error('A propriedade results não é um array:', data);
        }

      } catch (error) {
        console.error('Erro ao buscar as partidas ao vivo:', error);
      } finally {
        this.isLoading = false; // Deactivate the loading indicator
      }
    }
  },
  mounted() {
    this.fetchMatches(); // Load the initial data
    // Update the data every 10 seconds
    this.interval = setInterval(this.fetchMatches, 10000);
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
};
</script>



<style scoped>
.live-matches-container {
  background-color: #383838;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinha os itens no topo */
}

.live-matches-title {
  color: var(--color-text);
  text-align: center;
  margin-bottom: 0.5rem; /* Reduzir o espaço abaixo do título */
}

.match-cards-container {
  max-height: 40vh;
  overflow-y: auto;
  flex-grow: 1; /* Permite que o contêiner cresça para ocupar o espaço restante */
  position: relative; /* Adiciona posição relativa para o spinner */
}

.match-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: linear-gradient(to bottom right, var(--color-background-light), var(--color-background-dark));
  color: #fff;
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  flex-wrap: nowrap; /* Evitar quebra de linha */
}

.match-card:hover {
  transform: translateY(-3px);
}

.match-card.dale-match {
  border: 2px solid #383838;
}

.match-teams {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  flex-shrink: 0;
  color: var(--color-win);
  font-size: 0.9rem;
  font-weight: 700;
  margin-right: 1rem;
  text-align: center;
  height: 100%;
}

.match-teams span {
  display: block;
  width: 100%;
  text-align: center;
  margin: auto;
}

.match-details {
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #7f8c8d;
  flex-direction: row;
}

.match-date {
  margin-right: 1rem;
}

.match-score {
  margin-right: 1rem;
}

.match-bots {
  display: flex;
  flex-wrap: nowrap; /* Evitar quebra de linha */
  align-items: center;
}

.bot-badge {
  font-weight: bold;
  font-size: 0.8rem; /* Tamanho menor */
  color: #126E51;
  border: 1px solid #126E51; /* Borda discreta */
  text-transform: uppercase; /* Texto em maiúsculas */
  padding: 0 4px; /* Espaçamento interno menor */
  margin: 0 2px; /* Espaçamento externo menor */
}

.match-icon,
.match-icon-placeholder {
  width: 24px;
  height: 24px;
}

.match-cards-container::-webkit-scrollbar {
  width: 10px;
}

.match-cards-container::-webkit-scrollbar-track {
  margin-left: 2rem;
  background: #383838;
}

.match-cards-container::-webkit-scrollbar-thumb {
  background: #1abc9c;
  border-radius: 5px;
}

.match-cards-container::-webkit-scrollbar-thumb:hover {
  background: #05846b;
}

.svg-icon {
  margin: 0;
  padding: 0;
  height: 2rem;
  width: auto;
  align-self: center;
}

.no-results {
  color: #ccc;
  text-align: center;
  padding: 20px;
}

.spinner-container {
  position: absolute;
  top: 30%;
  left: 43%;
  transform: translate(-50%, -50%);
}

.vue-spinner {
  font-size: 4rem;
  color: var(--color-win);
}
</style>
