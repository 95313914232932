<template>
  <div class="bets-table-wrapper">
    <div class="controls-container">
      <select class="ranking-select" v-model="localSelectedOption" @change="onSelectionChange">
        <option value="matches">Todas as Partidas</option>
        <option value="draws">Somente Empates</option>
        <option v-for="bot in botPermissions" :key="bot.id" :value="bot.id">{{ bot.name }}</option>
      </select>
      <div class="search-container">
        <input type="text" class="status-search" placeholder="Pesquisar..." v-model="searchTerm" />
        <div class="search-icon"></div>
      </div>
    </div>
    <VueSpinner v-if="localIsLoading" class="spinner-container"/>
    <div v-else>
      <div v-if="games.length">
        <GameRow v-for="game in filteredGames" :key="game.id" :game="game" />
      </div>
      <div v-else class="no-results">
        Não foram encontrados itens que correspondam à sua pesquisa.
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import GameRow from './GameRow.vue';
import { VueSpinner } from 'vue3-spinners';
import { fetchMatches, fetchDraws, fetchDale } from '@/services/matchesService';

export default {
  name: 'GameTable',
  components: {
    GameRow,
    VueSpinner
  },
  props: {
    selectedOption: {
      type: String,
      default: 'matches'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      games: [],
      searchTerm: '',
      localSelectedOption: this.selectedOption,
      botPermissions: JSON.parse(localStorage.getItem('botPermissions') || '[]'),
      localIsLoading: false,
      totalPages: 0,
      currentPage: 1
    };
  },
  watch: {
    selectedOption(newVal) {
      this.localSelectedOption = newVal;
      this.fetchGamesDebounced(newVal, 1); // Reset para página 1 ao mudar a opção
    },
    searchTerm: 'onSearchInputDebounced',
    isLoading(newVal) {
      // Sincroniza o estado de carregamento recebido do componente pai
      this.localIsLoading = newVal;
    }
  },
  computed: {
    filteredGames() {
      if (!this.games || this.games.length === 0) return [];
      
      // Se não tiver termo de busca, retorna todos os jogos
      if (!this.searchTerm || this.searchTerm.trim() === '') {
        return this.games;
      }
      
      // Aplica o filtro apenas localmente se houver termo de busca
      const searchTermLower = this.searchTerm.toLowerCase().split(' ');
      return this.games.filter((game) => {
        return searchTermLower.every(term =>
          game.times.toLowerCase().includes(term) ||
          game.placar.replace(':', 'x').includes(term)
        );
      });
    }
  },
  methods: {
    async fetchGames(option, page = 1) {
      // Notifica o componente pai que está carregando
      this.$emit('update:isLoading', true);
      this.currentPage = page;
      
      try {
        console.log(`Buscando página ${page} de ${option}`);
        let data;
        if (option === 'matches') {
          data = await fetchMatches(page, this.searchTerm);
        } else if (option === 'draws') {
          data = await fetchDraws(page, this.searchTerm);
        } else {
          data = await fetchDale(option, page, this.searchTerm);
        }
        
        // Verifica se recebemos dados válidos
        if (data && data.results) {
          this.games = data.results;
          this.totalPages = Math.ceil(data.count / 30); // Assumindo 30 itens por página
          console.log(`Página ${page}: Recebidos ${data.results.length} itens`);
        } else {
          console.error("Dados inválidos recebidos:", data);
          this.games = [];
        }
        
        this.$emit('update:totalPages', this.totalPages);
      } catch (error) {
        console.error("Erro ao buscar partidas:", error);
        this.games = [];
      } finally {
        // Notifica o componente pai que terminou de carregar
        this.$emit('update:isLoading', false);
      }
    },
    fetchGamesDebounced: debounce(function(option, page = 1) {
      this.fetchGames(option, page);
    }, 300), // Debounce com 300ms de atraso

    onSearchInputDebounced: debounce(function() {
      // Reset para página 1 ao pesquisar
      this.fetchGames(this.localSelectedOption, 1);
    }, 300), // Debounce com 300ms de atraso

    onSelectionChange(event) {
      const value = event.target.value;
      this.localSelectedOption = value;
      this.searchTerm = ''; // Limpa o termo de busca ao trocar de opção
      this.fetchGamesDebounced(value, 1); // Reset para página 1 ao mudar a opção
    }
  },
  mounted() {
    this.fetchGames(this.localSelectedOption, 1);
  }
};
</script>

<style scoped>
.vue-spinner {
  font-size: 4rem;
  color: var(--color-win);
}
.spinner-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 55vh;
  bottom: 15vh;
}
.table-header-fixed {
  position: sticky;
  top: 0;
  background-color: #282828;
  z-index: 5;
}

.bets-table-wrapper {
  overflow-y: auto;
  max-height: 65vh;
  margin-bottom: 2rem;
}

.no-results {
  color: #ccc;
  text-align: center;
  padding: 20px;
}

.bets-table-wrapper::-webkit-scrollbar-track {
  background-color: #282828;
}

.bets-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.bets-table-wrapper::-webkit-scrollbar {
  width: 8px;
}

.bets-table-wrapper {
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
  margin-right: -1rem;
  padding-right: 1rem;
  max-height: 80vh;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  max-width: 50%;
}

.status-search {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.6em 0.8em 0.6em 2.5em;
  border: none;
  border-radius: 20px;
  background-color: #383838;
  color: white;
  font-size: 0.8rem;
  outline: none;
}

.ranking-select {
  min-width: 3rem;
  width: 14rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  padding: 0.6em 1em;
  border: none;
  border-radius: 20px;
  background-color: #383838;
  color: white;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .table-header-fixed {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .bets-table-wrapper {
    max-height: 100vh;
    padding-right: 0.5rem;
  }

  .no-results {
    font-size: 0.9rem;
  }
  .controls-container {
    flex-direction: column;
    align-items: stretch;
  }
  .search-container {
    width: 100%;
    margin-top: 1rem;
  }
  .bets-table-wrapper{
    height: 100vh;
  }
  .spinner-container {
    width: 30%;
    height: 30%;
    position: fixed;
    margin: 0;
    top: 2rem;
    left: 7rem;
  }
}
</style>
