<template>
  <DaleHeader />
  <div class="games-page-container">
    <div class="games-content">
      <div class="games-table-wrapper">
        <div class="table-pagination-container">
          <VueSpinner v-if="isLoading" class="spinner-container"/>
          <GameTable
            ref="gameTable"
            :selected-option="selectedOption"
            :is-loading="isLoading"
            @update:isLoading="updateLoading"
            @update:totalPages="updateTotalPages"
            @fetch-games="fetchGames"
          />
          <GamePagination
            v-if="totalPages > 1"
            :current-page="currentPage"
            :total-pages="totalPages"
            @page-changed="changePage"
            class="pagination-wrapper"
          />
        </div>
        <div class="vertical-separator"></div>
      </div>
      <div class="ranking-wrapper">
        <RankingTable :players="players" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueSpinner } from 'vue3-spinners';
import DaleHeader from '../components/Header.vue';
import GameTable from '../components/GameTable.vue';
import RankingTable from '../components/Ranking.vue';
import GamePagination from '../components/GamePagination.vue';

export default {
  name: 'TodasPartidas',
  components: {
    DaleHeader,
    GameTable,
    RankingTable,
    GamePagination,
    VueSpinner
  },
  data() {
    return {
      players: [],
      isLoading: false,
      intervalId: null,
      selectedOption: 'matches',
      currentPage: 1,
      totalPages: 0,
      lastUpdateTimestamp: null,
    };
  },
  mounted() {
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  },
  methods: {
    changePage(page) {
      if (this.currentPage === page) return; // Evita recarregar a mesma página
      
      this.currentPage = page;
      this.isLoading = true;
      this.fetchGames(this.selectedOption, page);
    },
    startPolling() {
      this.intervalId = setInterval(() => {
        this.fetchGames(this.selectedOption, this.currentPage);
      }, 300000);
    },
    stopPolling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    updateLoading(isLoading) {
      this.isLoading = isLoading;
    },
    updateTotalPages(totalPages) {
      this.totalPages = totalPages;
    },
    fetchGames(option, page = 1) {
      if (this.$refs.gameTable) {
        this.$refs.gameTable.fetchGames(option, page);
      }
    }
  },
  watch: {
    selectedOption(newVal) {
      this.currentPage = 1;
      this.fetchGames(newVal);
    }
  }
};
</script>

<style scoped>
.games-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Altura mínima da janela */
  padding: 1rem; /* Ajuste conforme necessário */
  background-color: #282828; /* Cor de fundo da página */
}

.games-content {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  position: relative; /* Adicionado para posicionar a barra separadora */
  background-color: #282828; /* Cor de fundo para a página */
  padding: 1rem;
}

.games-table-wrapper {
  flex: 0 0 70%;
  overflow: hidden;
  position: relative; /* Adicionado para posicionamento do pseudo-elemento */
  padding-right: 20px; /* Espaçamento interno à direita para afastar o conteúdo da barra */
  position: relative; /* Isso garante que o z-index tenha efeito */
  z-index: 1;
}

.vertical-separator {
  position: absolute;
  right: 0; /* Ajusta a barra para a borda direita do conteúdo do .games-table-wrapper */
  top: 0; /* Inicia um pouco para baixo do topo do contêiner */
  bottom: 0; /* Termina um pouco acima do fundo do contêiner */
  width: 1rem; /* Largura da barra */
  background-color: transparent;
  border-left: 1px solid rgba(109, 106, 106, 0.5); /* Cor da borda à esquerda com opacidade */
  border-right: 1px solid rgba(109, 106, 106, 0.5); /* Cor da borda à direita com opacidade */
  opacity: 0.2; /* Opacidade para um visual mais suave */
  z-index: 2; /* Isto precisa ser maior que o z-index do .games-table-wrapper */
}
.ranking-wrapper {
  flex: 1; /* Ranking ocupa 30% do contêiner flexível */
  overflow-y: auto; /* Ativa a rolagem vertical se o conteúdo transbordar */
  max-height: 100vh; /* Altura máxima para ativar a rolagem */
}

.pagination-wrapper {
  min-height: 50px; /* Altura mínima para garantir que o componente ocupe espaço */
  min-width: 100%; /* Largura mínima para tornar o componente visível */
}

.table-pagination-container {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Garante que os filhos ocupem toda a largura disponível */
}

.spinner-container {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  width: 10%; /* Ocupa toda a largura da tela */
  height: 10%; /* Ocupa toda a altura da tela */
  position: absolute; /* Usa posição absoluta para ficar no centro do contêiner */
  top: 2rem;
  left: 30rem;
  z-index: 3; /* Certifica-se de que o spinner fique acima dos outros elementos */
}

.vue-spinner {
  /* Customize aqui, se necessário. Por exemplo: */
  font-size: 4rem; /* Ajusta o tamanho do spinner */
  color: var(--color-win); /* Exemplo de cor, ajuste conforme necessário */
}

@media (max-width: 768px) {
  .games-content {
    flex-direction: column;
  }

  .games-table-wrapper,
  .ranking-wrapper {
    flex: 0 0 auto; /* Faz com que cada seção ocupe a altura necessária */
  }
  .vertical-separator {
    opacity: 0;
  }

  .spinner-container {
    width: 50%;
    height: 50%;
    position: fixed; /* Usa posição fixa para ficar visível mesmo com rolagem */
    margin: 0;
    top: 2rem;
    left: 6rem;
  }
}
</style>
