<template>
  <div class="login-container">
    <h1>Login</h1>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <input 
          id="username" 
          v-model="username" 
          required 
          class="form-control" 
          placeholder="Usuário"
        />
      </div>
      <div class="form-group">
        <input 
          id="password" 
          type="password" 
          v-model="password" 
          required 
          class="form-control" 
          placeholder="Senha"  
        />
      </div>
      <button type="submit" class="btn-login">Entrar</button>
      <div v-if="loginErrorMessage" class="login-error-message">
        {{ loginErrorMessage }}
      </div>
    </form>
    <div class="register-link-container">
      Não tem conta? <router-link class="router-link" to="/register">Registre-se</router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

// const API_BASE_URL = 'https://api.dale365.com/api/v1/login/';
const API_BASE_URL = 'https://api.dale365.com/api/v1/login/';

export default {
  name: 'UserLogin',
  setup() {
    const router = useRouter();
    const username = ref('');
    const password = ref('');
    const loginErrorMessage = ref('');

    const handleLogin = async () => {
      loginErrorMessage.value = '';

      if (!username.value || !password.value) {
        loginErrorMessage.value = 'Usuário e senha são obrigatórios.';
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: username.value,
            password: password.value
          })
        });

        const data = await response.json();

        if (response.ok && data.token) {
          localStorage.setItem('userToken', data.token);
          localStorage.setItem('userLoggedIn', 'true');
          localStorage.setItem('username', username.value);
          localStorage.setItem('botPermissions', JSON.stringify(data.bot_permissions));
          router.push('/partidas');
        } else {
          if (data.detail === 'Invalid credentials.') {
            loginErrorMessage.value = 'Usuário ou senha inválidos.';
          } else if (data.detail === 'User is not active.') {
            loginErrorMessage.value = 'Usuário não ativo, contate o administrador para ter o registro ativo';
          } else {
            loginErrorMessage.value = 'Login falhou. Nenhum token foi retornado.';
          }
        }
      } catch (error) {
        loginErrorMessage.value = 'Erro ao fazer login. Por favor, tente novamente mais tarde.';
      }
    };

    return {
      username,
      password,
      handleLogin,
      loginErrorMessage,
    };
  },
};
</script>

<style scoped>
.login-error-message {
  margin-top: 20px;
  color: #ccc; /* Exemplo de cor, ajuste conforme necessário */
  font-size: 1rem;
}
.login-container {
  max-width: 400px;
  margin: 5% auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #282828; /* Cor de fundo do exemplo */
  color: #ccc; /* Cor do texto do exemplo */
}

h1 {
  color: #ccc; /* Cor do texto do exemplo */
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem; /* Tamanho da fonte do exemplo */
}

.form-group {
  margin-bottom: 15px;
}
.btn-login {
  width: 100%;
  padding: 10px;
  background-color: #1abc9c; /* Cor do botão do exemplo */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold; /* Texto em negrito como no exemplo */
  font-size: 1rem; /* Tamanho da fonte do exemplo */
}

.btn-login:hover {
  background-color: #16a085; /* Cor de fundo do botão ao passar o mouse, do exemplo */
}

/* Adicione estilos para os placeholders conforme o exemplo, se necessário */
.form-control {
  width: 100%;
  padding: 1rem; /* Espaçamento interno maior para os inputs */
  border: 1px solid #ccc; /* Borda sutil */
  border-radius: 4px; /* Cantos arredondados */
  box-sizing: border-box; /* Mantém a largura incluindo o padding */
  background-color: #fff; /* Fundo branco */
  font-size: 1rem; /* Tamanho de fonte confortável */
  color: #333; /* Cor de texto escura */
  margin-bottom: 0; /* Remove a margem inferior para ajustar com o placeholder */
}

/* Estilos para o placeholder */
.form-control::placeholder {
  color: #888; /* Cor do placeholder */
  opacity: 1; /* Opacidade para visibilidade total */
  font-size: 1rem; /* Tamanho da fonte igual ao texto do input */
}

.register-link-container {
  text-align: center; /* Centraliza o texto */
  margin-top: 15px; /* Espaço acima do link */
}

.router-link { /* Estilo para o link de registro */
  text-decoration: none; /* Remove o sublinhado do link */
}

.router-link:hover { /* Estilo para o hover do link de registro */
  text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */
}
</style>
